<template>
  <div class="dashboard">
    <div class="dashboard-top">
      <h1>Bienvenue, {{first_name}}</h1>
    </div>
    <div class="dashboard-content">
      <Statistics id="statistics"></Statistics>
      <div id="important">
        <div class="tickets-important">
          <h3>Tickets urgents   <a @click="$router.push('/tickets')">Voir plus</a> </h3>
          <ImportantTickets></ImportantTickets>
        </div>
        <div class="tickets-important">
          <h3>Tâches urgentes</h3>
          <ImportantTasks></ImportantTasks>
        </div>
        <div class="tickets-important">
          <h3>Intranet G-paie</h3>
          <div class="man">
            <span>Télécharger le manuel d'utilisation :</span>
            <br>
            <a href="/Manuel_Intranet.pdf"><button><fa icon="file-pdf"></fa> Télécharger la notice</button> </a>
          </div>
          <span>Version et support :</span>
            <br>
          <div class="version-dev">
            <div id="version">
              <span>Version {{version}}</span>
            </div>
            <button type="button" @click="goDev">Tickets DEV</button>
          </div>
          <span>Réalisé par <a href="https://linkedin.com/in/faure-alexis">Alexis FAURE</a><br><a href="mailto:alexis.faure.dev@gmail.com">alexis.faure.dev@gmail.com</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Statistics from '../components/UI/Statistics.vue';
import ImportantTickets from '../components/Tickets/ImportantTickets.vue';
import ImportantTasks from '../components/UI/Dashboard/ImportantTasks.vue';


export default {
    name: "Dashboard",
    data() {
        return {
            first_name: "",
            version: process.env.VERSION
        };
    },
    methods: {
      goDev() {
        this.$router.push('/dev/tickets');
      }
    },  
    mounted() {
        this.first_name = this.$store.state.User.first_name;
        this.version = process.env.VERSION;
    },
    components: { Statistics, ImportantTickets, ImportantTasks }
}
</script>

<style scoped>
#statistics {
  margin: 2%;
}

#important {
  display: flex;
  flex-wrap: wrap;
}

.tickets-important {
  width: 45%;
  background-color: whitesmoke;
  padding: 10px;
  margin-left: 2%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
  margin-bottom: 20px;
}

.tickets-important:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.version-dev {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

button {
    padding: 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

#version, button {
  margin-top: 10px;
}

#version {
    color: lightseagreen;
    background-color: #dcffe2;
    border: solid 1px lightseagreen;
    border-radius: 10px;
    padding: 5px;
    margin-right: 20px;
}

.man {
  margin-block: 20px;
}

a {
  color: lightseagreen;
  text-decoration: none;
}
</style>
