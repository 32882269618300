<template>
    <div id="component-root">
        <table>
            <tr>
                <th></th>
                <th>Client</th>
                <th>Tâche</th>
                <th>Date</th>
                <th>Statut</th>
                <th>Priorité</th>
            </tr>
            <tr v-for="task in Tasks">
                <td class="colorhead" v-bind:class="{ green: isGreen, pink: isPink, yellow: isYellow}"></td>
                <td>{{task.company}}</td>
                <td>{{task.name}}</td>
                <td>{{getDate(task.day)}}</td>
            </tr>
        </table>
        <span v-if="!Tasks.lemgth">Aucune tâche urgente</span>
    </div>
</template>

<script>
const axios = require('axios');
const getTaskType = require('../../../scripts/tasks/getTaskType');
export default {
    name: 'Today Tasks',
    data() {
        return {
            rawRes: [],
            Tasks: [],
            isGreen: false,
            isPink: false,
            isYellow: false
        }
    },
    computed: {
    },
    methods: {
        getDate(day) {
            const date = new Date();
            const month = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Decembre"];
            const current = month[date.getMonth()];
            return `${day.toString()} ${current}`
        },
        parseTasks() {
            this.rawRes.forEach(el => {
                let name = el.name;
                let company = el.company;
                if (el.tasks) {
                    el.tasks.forEach(task => {
                        task.person = name;
                        task.company = company;
                        this.Tasks.push(task);
                    });
                }
            })
        },
        fetchTodayTasks() {
            axios.get(`${this.$store.state.BaseUrl}/tasks/today`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.rawRes = res.data;
                console.log(res.data);
                this.parseTasks();
            }).catch(err => console.log(err));
        },
    },
    mounted() {
        this.fetchTodayTasks();
    }
}
</script>

<style scoped>
table, tr{
    width: 100%;
}

.colorhead {
    padding: 5px;
    background-color: green;
    border-radius: 0px 20px 20px 0px;
}

.green {
    background-color: green;
}

.yellow {
    background-color: gold;
}

.pink {
    background-color: palevioletred;
}
</style>
