<template>
    <div id="footer">
        
    </div>
</template>

<script>
export default {

}
</script>

<style>

#footer {
    height: 300px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100vw;
    background-repeat:no-repeat;
    background-position: bottom;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath fill='%2300cba9' fill-opacity='1' d='M0,160L40,144C80,128,160,96,240,122.7C320,149,400,235,480,229.3C560,224,640,128,720,117.3C800,107,880,181,960,197.3C1040,213,1120,171,1200,149.3C1280,128,1360,128,1400,128L1440,128L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z'%3E%3C/path%3E%3C/svg%3E");
    margin: 0 !important;

}

</style>
