<template>
  <nav>
    <div class="logo">
        <img alt="Logo gpaie" src="../../assets/logo.jpg"/>
    </div>
    
    <div class="navigation">
        <router-link class="nav-item" to="/">Tableau de bord</router-link>
        <router-link class="nav-item" to="/customers">Clients</router-link>
        <router-link class="nav-item" to="/wallets">Portefeuilles</router-link>
        <router-link class="nav-item" to="/tasks">Tâches</router-link>
        <router-link class="nav-item" to="/tickets">Tickets</router-link>
        <router-link class="nav-item" to="/products">Produits</router-link>
        <router-link class="nav-item" to="/collaborators" v-if="is_admin === 1">Collaborateurs</router-link>
        <router-link class="nav-item" to="/billing">Factures</router-link>
        <router-link class="nav-item" to="/mandats">Mandats</router-link>
    </div>
    <div class="user">
        <div class="user-image">
            {{letter}}
            </div>
        <div class="user-name">
            <h4>{{name}}</h4>
        </div>
        <fa @click="logout()" class="fa-icon" icon="right-from-bracket"></fa>
    </div>
  </nav>
</template>

<script>

export default {
    data() {
        return {
            is_admin: false,
            letter: "?",
            name: ""
        }
    },
    methods: {
        logout() {
            this.$store.commit('setLogout', true);
            this.$router.push('/');
        }
    },
    mounted() {
        if (this.$store.state.Connected === true) {
            this.name = this.$store.state.User.first_name;
            this.letter = this.name[0];
        }
        this.is_admin = this.$store.state.User.is_admin;
    }
}
</script>

<style scoped>

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

nav .logo img {
    height: 50px;
}

.nav-item {
    margin-left: 10px;
}

a {
    color: darkgreen;
    text-decoration: none;
    padding: 0px 10px 10px 10px;
}

a.router-link-exact-active {
    color: lightseagreen;
    padding-bottom: 10px;
    padding: 0px 10px 10px 10px;
    border-bottom: 2px solid;
}

a:hover {
    color: lightseagreen;
    padding-bottom: 10px;
    padding: 0px 10px 10px 10px;
    border-bottom: 2px solid;
    opacity: 0.7;
}

.user {
    display: flex;
    align-items: center;
}

.user-image {
    cursor: pointer;
    order: 2;
    margin-left: 30px;
    background-color: lightseagreen;
    padding: 15px 20px 15px 20px;
    border-radius: 50%;
    border: 2px solid darkgreen;
}

.fa-icon {
    cursor: pointer;
    margin-left: 20px;
}

</style>
