import { createStore } from 'vuex'
const axios = require('axios');

export default createStore({
  state: {
    Bearer: "",
    User: {},
    BaseUrl: "https://gpaie-backend.herokuapp.com",
    Connected: false,
    UserList: [],
    CustomersList: [],
    WalletList: [],
    ProductsList: [],
    twofa: false,
    defaultICS: "FR54 8335C1"
  },
  mutations: {
    updateUser(state) {
      axios.get(`${state.BaseUrl}/user/${state.User.id}`, {
        headers: {
          Authorization: `Bearer ${state.Bearer}`
        }
      }).then(res => {
        state.User = res.data;
      }).catch(err => console.log(err));
    },
    initialiseStore(state) {
			if(localStorage.getItem('store-gpaie')) {
        this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store-gpaie')))
				);
			}
		},
    setUser: (state, payload) => {
      state.User = payload
    },
    setConnected: (state, payload) => {
      state.Connected = payload
    },
    setBearer: (state, payload) => {
      state.Bearer = payload
    },
    setLogout: (state, payload) => {
      state.Bearer = '';
      state.Connected = false;
      state.twofa = false;
      state.User = {};
    },
    setUserList: (state, payload) => {
      state.UserList = payload;
    },
    setCustomersList: (state, payload) => {
      state.CustomersList = payload;
    },
    setWalletList: (state, payload) => {
      state.WalletList = payload;
    },
    setProductsList: (state, payload) => {
      state.ProductsList = payload;
    },
    setTwoFa: (state, payload) => {
      state.twofa = true;
    }
  },
  actions: {
  },
  modules: {
  }
})
