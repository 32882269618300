<template>
  <nav-bar v-if="connected"/>
  <router-view class="rooter"/>
  <Footer class="footer"></Footer>
</template>

<script>
import NavBar from './components/UI/NavBar.vue';
import Footer from './components/UI/Footer.vue';


export default {
    data() {
      return {
        connected: false,
      }
    },
    methods: {
      checkNav() {
        if (this.$store.state.User.first_name !== undefined) {
            this.connected = true;
          }
      }
    },
    mounted() {
      this.checkNav();
    },
    components: { NavBar, Footer }
}
</script>

<style>

* {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  overflow-x: hidden;
}

.footer {
  margin-top: 50px;
  z-index: -1;
}

</style>
