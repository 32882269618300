<template>
    <div id="component-root">
        <table>
        <tr>
            <th>Id</th>
            <th>Client</th>
            <th>Titre</th>
            <th>Statut</th>
            <th>Priorité</th>
        </tr>
        <tr v-for="ticket in ticketsList.slice(0, 5)" @click="viewTicket(ticket.id)" class="ticket-row">
            <td>{{ticket.id}}</td>
            <td>{{getCustomerName(ticket.customer_id)}}</td>
            <td>{{ticket.title}}</td>
            <td>{{ticket.status.toUpperCase()}}</td>
            <td class="badge-container">
            <Badge :text="ticket.priority.toUpperCase()" :type="selectType(ticket.priority)"></Badge>
            </td>
        </tr>
    </table>
    </div>
</template>

<script>
import Badge from '../utils/Badge.vue';
const findCustomer = require('../../scripts/customer/findCompany');
const axios = require('axios');
export default {
    name: 'Important tickets',
    data() {
        return {
            ticketsList: []
        }
    },
    methods: {
        getCustomerName(id) {
            return findCustomer(id, this.$store.state.CustomersList);
        },
        fetchTickets() {
            axios.get(`${this.$store.state.BaseUrl}/tickets/all`, {
                headers : {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.ticketsList = res.data.filter(ticket => ticket.priority === "haute" && ticket.status !== "OK");
            }).catch(err => {
                console.log(err);
            })
        },
        viewTicket(id) {
            this.$router.push({ name: "Ticket", params: { id: id } });
        },
        selectType(priority) {
            switch(priority) {
                case 'bas':
                    return 'success';
                case 'normal':
                    return 'info';
                case 'haute': 
                    return 'danger';
                default: 
                    return 'warning';
            }
        }
    },
    mounted() {
        this.fetchTickets();
    },
    components: { Badge }
}
</script>

<style scoped>
table {
    width: 100%;
}
.badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-row {
    cursor: pointer;
}
</style>
