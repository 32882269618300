<template>
  <div class="badge" :class="type"><span>{{text}}</span></div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        type: {
            required: true
        },
        text: {
            required: true
        }
    }
}
</script>

<style>
.badge {
    border: none;
    padding: 7px;
    margin: 5px;
    border-radius: 10px;
    width: 60%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge span {
    font-weight: bold;
    font-size: 15px;
    color: white;
}

.success {
    background-color: #4BB543;
}

.info {
    background-color: cornflowerblue;
}

.warning {
    background-color: #eed202;
}

.warning span {
    color: black;
}

.danger {
    background-color: #F32013;
}
</style>
