<template>
  <div id="login-container">
    <form v-if="$store.state.User['2fa_valid'] === 1">
        <h1>Authentification à 2 facteurs</h1>
        <div class="input-container">
            <label>Code</label>
            <input v-model="input.code"  class="input-field" type="number" name="code" placeholder="XXXXXX"/>
        </div>
        <div id="login-btns">
            <button class="submit" v-on:click="checkCode()" type="button">Valider</button>
            <a class="issue" href="#">Impossible de se connecter ?</a>
        </div>
    </form>
    <div v-else>
        <h1>Authentification à 2 facteurs</h1>
        <span>La 2AF est obligatoire pour accéder à l'intranet</span>
        <div class="step">
            <h3>Étape 1</h3>
            <span>Scannez le code qr ci-dessous via l'application Google Authenticator</span>
            <img ref="qrcode" :src="blobUrl" @load="loaded">
        </div>
        <div class="step">
            <h3>Étape 2</h3>
            <span>Une fois le code scanné entrez le code a 6 chiffres correspondant à <strong>G-PAIE Intranet</strong> ci-dessous</span>
            <div class="input-and-btn">
                <input type="number" class="input-field" v-model="input.firstCode" required>
                <button class="submit" type="button" @click="enableTwoFa()">Vérfier le code</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import router from '../../router';

export default {
    data() {
        return {
            blobUrl: null,
            input: {
                code: null,
                firstCode: null
            },
            User: {}
        }
    },
    mounted() {
        if (this.$store.state.User.first_name) {
            router.push('/');
        }
        this.User = this.$store.state.User;
        if (this.$store.state.User['2fa_valid'] === 0) {
            this.getQrCode();
        }
    },
    methods: {
        loaded() {
            if (this.blobUrl) URL.revokeObjectURL(this.blobUrl)
        },
        ImageFromStream(fileStream) {
            return window.URL.createObjectURL()
        },
        getQrCode() {
            axios.get(`${this.$store.state.BaseUrl}/2fa/enable`, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.blobUrl = URL.createObjectURL(res.data);
            })
        },
        enableTwoFa() {
            if (this.input.firstCode.toString().length != 6) {
                return createToast({ title: 'Erreur', description: 'Code invalide !'}, {type: 'danger', showIcon: true});
            }
            axios.post(`${this.$store.state.BaseUrl}/2fa/activate`, {
                email: this.User.email,
                code: this.input.firstCode.toString()
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$store.commit('setUser', res.data);
                this.$store.commit('setTwoFa', true);
                createToast({ title: 'Code valide', description: 'Vous allez etre redirigés automatiquement...'}, {type: 'success', showIcon: true, onClose: router.go()});
                router.push('/');
            }).then(err => {
                console.log(err);
                return createToast({ title: 'Erreur', description: 'Code invalide !'}, {type: 'danger', showIcon: true});
            })
        },
        checkCode() {
            if (this.input.code.toString().length != 6) {
                return createToast({ title: 'Erreur', description: 'Code invalide !'}, {type: 'danger', showIcon: true});
            }
            axios.post(`${this.$store.state.BaseUrl}/2fa/verify`, {
                code: this.input.code.toString()
            },
            {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$store.commit('setUser', res.data);
                this.$store.commit('setTwoFa', true);
                createToast({ title: 'Code valide', description: 'Vous allez etre redirigés automatiquement...'}, {type: 'success', showIcon: true, onClose: router.go()});
                router.push('/');
            }).catch(err => {
                console.log(err);
                return createToast({ title: 'Erreur', description: 'Code invalide !'}, {type: 'danger', showIcon: true});
            })
            
        }
    }
}
</script>

<style scoped>

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

label {
    font-size: 20px;
    padding-bottom: 20px;
}

#login-container {
    display: flex;
    justify-content: center;
}

form {
    display: flex;
    flex-direction: column;
    border: 1px solid lightseagreen;
    border-radius: 20px;
    width: 40%;
    padding: 20px;
}

input { 
    margin-bottom: 20px;
    text-align: center;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#login-btns {
    margin-top: 10px;
    display : flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.input-field {
    align-items: center;
    border-radius: 20px;
    height: 30px;
    width: 50%;
    font-size: 24px;
    padding: 5px;
}

.submit {
    cursor: pointer;
    width: 50%;
    border-radius: 10px;
    border: none;
    height: 40px;
    background-color: lightseagreen;
    color: white;
    margin-bottom: 20px;
}

.issue {
    font-size: 14px;
}

.submit:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.input-and-btn {
    margin-top: 30px;
    display: flex;
    align-items: center;
}

.input-and-btn button {
    width: 20%;
    margin-left: 20px;
}

</style>
