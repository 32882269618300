findCustomerCompany = (id, customerList) => {
    var cpy = "Client inconnu";
    customerList.forEach(element => {
        if (element.id === id) {
            cpy = element.company
            return cpy;
        }
    });
    return cpy;
}

module.exports = findCustomerCompany;
