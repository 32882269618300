<template>
  <div id="statistics">
    <div id="card-container">
        <div class="card" @click="$router.push('/customers')">
            <h2>{{stats.customers}}</h2>
            <span>Clients</span>
        </div>
        <div class="card" @click="$router.push('/tickets')">
            <h2>{{stats.tickets}}</h2>
            <span>Tickets</span>
        </div>
        <div class="card" @click="$router.push('/tasks')">
            <h2>{{stats.tasks}}</h2>
            <span>Tâches</span>
        </div>
        <div class="card" v-if="showMoney">
            <h2>{{stats.bill.toFixed(2)}}€</h2>
            <span>Facturés</span>
        </div>
    </div>
    <LoadData></LoadData>
  </div>
</template>

<script>
import LoadData from '../LoadData.vue';
const axios = require('axios');
export default {
    name: "Stats",
    data() {
        return {
            showMoney: false,
            stats: {
                customers: 0,
                tickets: 0,
                tasks: 0,
                bill: 0
            }
        };
    },
    methods: {
        getStats() {
            axios.get(`${this.$store.state.BaseUrl}/stats/dashboard`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.stats.customers = res.data.customers;
                this.stats.tickets = res.data.tickets;
                this.stats.bill = res.data.bill;
            }).catch(err => {
                console.log(err);
            });
        }
    },
    mounted() {
        this.getStats();
    },
    components: { LoadData }
}
</script>

<style>
#statistics {
    border-radius: 20px;
    background-color: lightseagreen;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
}

#card-container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    cursor: pointer;
    margin: 2%;
    width: 20%;
    min-width: 235px;
    padding: 5px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s; 
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

h2 {
    font-size: 38px
}

span {}

</style>
