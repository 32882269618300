<template>
  <div id="login-container">
    <form>
        <h1>Connexion</h1>
        <div id="incorrect-login" v-if="wrong">
            <h3>Identifiants incorrects</h3>
            <span>Merci de vérfier que vous avez entré le bon combo email/mot de passe.</span>
        </div>
        <div class="input-container">
            <label>Email</label>
            <input v-model="input.email" class="input-field" type="email" name="email" placeholder="john.doe@g-paie.fr"/>
        </div>
        <div class="input-container">
            <label>Mot de passe</label>
            <input v-model="input.password" class="input-field" type="password" name="password" placeholder="*******"/>
            </div>
        <div id="login-btns">
            <button class="submit" v-on:click="login()" type="button" @keyup.enter="login()">Connexion</button>
            <button class="forgot" type="button">Mot de passe oublié ?</button>
        </div>
        
    </form>
  </div>
</template>

<script>
const axios = require('axios');

export default {
    name: 'Login',
    data() {
        return {
            wrong: false,
            input: {
                email : "",
                password: ""
            }
        }
    },
    methods: {
        login() {
            let id = 0;
            if (this.input.email === "" || this.input.password === "")
                return;
            axios.post(`${this.$store.state.BaseUrl}/auth/login`, {
                email: this.input.email,
                password: this.input.password
            }).then(res => {
                this.$store.commit('setConnected', true);
                this.$store.commit('setBearer', res.data.token);
                this.bearer = this.$store.state.Bearer;
                this.connected = this.$store.state.Connected;
                axios.get(`${this.$store.state.BaseUrl}/user/list/all`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`
                    }
                }).then(res => {
                    this.$store.commit('setUserList', res.data);
                    for (let i = 0; i < this.$store.state.UserList.length; i++) {
                        if (this.$store.state.UserList[i].email === this.input.email) {
                            id = this.$store.state.UserList[i].id
                        }
                    }
                    axios.get(`${this.$store.state.BaseUrl}/user/${id}`, {
                        headers: {
                            Authorization: `Bearer ${this.$store.state.Bearer}`
                        }
                    }).then(res => {
                        this.$store.commit('setUser', res.data);
                    }).catch(err => {
                        console.log(err);
                    })
                }).catch(err => console.log(err));
                this.$router.push('/');
            }).catch(err => {
              //afficher message derreur
                console.log(err);
                if (err.message.includes("401") === true) {
                    console.log(401);
                    this.wrong = true;
                } else {
                    console.log(500)
                }
            })
        } 
    }
}
</script>

<style scoped>

h1 {
    margin-bottom: 20px;
}

label {
    font-size: 20px;
    padding-bottom: 20px;
}

#login-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

form {
    display: flex;
    flex-direction: column;
    border: 1px solid darkgreen;
    border-radius: 20px;
    width: 40%;
    padding: 20px;
}

input { 
    margin-bottom: 20px;
    text-align: center;
}

.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#login-btns {
    margin-top: 10px;
    display : flex;
    justify-content: space-around;
}

.input-field {
    align-items: center;
    border-radius: 20px;
    height: 30px;
    width: 70%;
    font-size: 24px;
    padding: 5px;
}

.submit {
    cursor: pointer;
    width: 40%;
    border-radius: 20px;
    border: none;
    height: 40px;
    background-color: lightseagreen;
    color: white;
}

.forgot {
    cursor: pointer;
    width: 40%;
    border-radius: 20px;
    height: 40px;
    border: 1px solid lightseagreen;
    background-color: white;
    color: lightseagreen;
}

.forgot:hover {
    background-color: lightseagreen;
    color: white;
}

.submit:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

#incorrect-login {
    color: red;
    background-color: #FFCCCB;
    border-radius: 10px;
    border: solid 1px red;
    margin-bottom: 10px;
}

</style>
