import { createRouter, createWebHistory, stringifyQuery } from 'vue-router'
import store from '../store/index';
import Home from '../views/Home.vue'
import Login from '../views/Auth/Login.vue'
import TwoFA from '../views/Auth/TwoFA.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowAnonymous: true
    },
    component: Login
  },
  {
    path: '/2fa',
    name: 'Two factors Auth',
    meta: {
      allowWithoutTFA: true
    },
    component: TwoFA
  },
  {
    path: '/customers',
    name: 'Customers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers.vue')
  },
  {
    path: '/create-customer',
    name: 'Create customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/CreateCustomer.vue')
  },
  {
    path: '/customers/:id',
    name: 'Customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/CustomerProfil.vue')
  },
  {
    path: '/customers/:id/edit',
    name: 'Edit Customer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Customers/EditCustomer.vue')
  },
  {
    path: '/tickets',
    name: 'Tickets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets.vue')
  },
  {
    path: '/tickets/:id',
    name: 'Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets/ViewTicket.vue')
  },
  {
    path: '/create-ticket/:id?',
    name: 'Create Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets/CreateTicket.vue')
  },
  {
    path: '/wallets',
    name: 'Wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Wallet.vue')
  },
  {
    path: '/create-wallet',
    name: 'Create Wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Wallet/CreateWallet.vue')
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue')
  },
  {
    path: '/products',
    name: 'Products',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Billing/Products.vue')
  },
  {
    path: '/create-product/:cpy?',
    name: 'Create Product',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Billing/CreateProduct.vue')
  },
  {
    path: '/collaborators',
    name: 'Collaborators',
    meta: {
      admin: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Collaborators/Collaborators.vue')
  },
  {
    path: '/billing',
    name: 'Billing',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Billing/Bill.vue')
  },
  {
    path: '/tasks',
    name: 'Tasks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tasks/Tasks.vue')
  },
  {
    path: '/tasks/:id',
    name: 'View Task',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tasks/ViewTasks.vue')
  },
  {
    path: '/dev/tickets',
    name: 'Dev Tickets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dev/Tickets.vue')
  },
  {
    path: '/dev/tickets/:id',
    name: 'Dev Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dev/ViewDevTicket.vue')
  },
  {
    path: '/dev/create',
    name: 'New Dev Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Dev/CreateDevTicket.vue')
  },
  {
    path: '/tickets/archived',
    name: 'Archived Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets/ArchivedTickets.vue')
  },
  {
    path: '/tickets/edit/:id',
    name: 'Edit Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets/EditTicket.vue')
  },
  {
    path: '/wallets/:id/:name',
    name: 'Edit Wallet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Wallet/EditWallet.vue')
  },
  {
    path: '/billing/export/:startDate/:endDate',
    name: 'Export Billing',
    component: () => import(/* webpackChunkName: "about" */ '../views/Billing/ExportBill.vue')
  },
  {
    path: '/mandats',
    name: 'Mandats',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mandats/Mandats.vue')
  },
  {
    path: '/mandats/:id',
    name: 'View mandat',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mandats/ViewMandat.vue')
  },
  {
    path: '/mandats/create',
    name: 'Create mandat',
    component: () => import(/* webpackChunkName: "about" */ '../views/Mandats/CreateMandat.vue')
  },
  {
    path: '/tasks/edit/:id',
    name: 'Edit Task',
    component: () => import(/* webpackChunkName: "about" */ '../views/Tasks/EditTask.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  if (!store.state.Connected && to.meta.allowAnonymous !== true) {
    router.push('/login');
  }
  if (store.state.Connected && !store.state.twofa && to.meta.allowWithoutTFA !== true) {
    router.push('/2fa');
  }
  if (store.state.Connected && to.meta.admin === true && store.state.User.is_admin === 0) {
    router.push('/');
  }
  if (store.state.Bearer.includes("ey")) {
    var token = store.state.Bearer;
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    var jwt = JSON.parse(jsonPayload);
    if (jwt.exp < Date.now()/1000) {
      store.commit("setLogout", null);
      router.push('/login');
    }
  }
  store.commit('updateUser');
})

export default router;
