function getTaskType(rawTasks) {
    const rawDate = new Date();
    const today = rawDate.getDate();
    if (rawTasks.bill_day === today) {
        return {type: "bill_day", color: "green"};
    } else if (rawTasks.pay_day === today) {
        return {type: "pay_day", color: "pink"};
    } else if (rawTasks.dsn_day === today) {
        return {type: "dsn_day", color: "yellow"};
    } else {
        return {type: "void"};
    }
}

module.exports = getTaskType;
