<template>

</template>

<script>
const axios = require('axios');
export default {
    name: 'Load Data',
    data() {
        return {

        }
    },
    methods: {
        fetchCustomers() {
            axios.get(`${this.$store.state.BaseUrl}/customers/all`,
            {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`}
            }).then(result => {
                this.$store.commit('setCustomersList', result.data);
            }).catch(err => {
                console.log(err);
            })
        },
        fetchWallets() {
            axios.get(`${this.$store.state.BaseUrl}/wallets/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$store.commit('setWalletList', res.data);
            }).catch(err => {
                console.log(err);
            })
        },
        fetchProducts() {
            axios.get(`${this.$store.state.BaseUrl}/products/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$store.commit('setProductsList', res.data);
            }).catch(err => {
                console.log(err);
            });
        }
    },
    mounted() {
        this.fetchCustomers();
        this.fetchWallets();
        this.fetchProducts();
    }
}
</script>

<style>

</style>
